﻿import Handlebars from 'handlebars';
import i18n from 'l10n';
import moment from 'moment';

import PaginationPartialTemplate from 'text-loader!../../js/templates/partials/pagination.partial.template.hbs';
import _ from 'underscore';

Handlebars.registerPartial('paginationPartialTemplate', PaginationPartialTemplate);

const toBlockHelper = predicate => _.wrap(predicate, function (...args) {
    const p = args.shift();
    if (!_.isFunction(p))
        throw Error("handlebars-helpers::booleanToBlockHelper -> given predicate wasn't a function");
    const predicateArgs = _.initial(args);
    const options = _.last(args);
    return p(...predicateArgs) ? options.fn(this) : options.inverse(this);
});

function replaceGreekAccents(str) {
    var replacements = {
        'Ά' : 'Α',
        'Ί' : 'Ι',
        'Ύ' : 'Υ',
        'Ή' : 'Η',
        'Ό' : 'Ο',
        'Ώ' : 'Ω',
        'Έ' : 'Ε',
        'Ϊ' : 'Ι',
        'Ϋ' : 'Υ'
    };

    $.each(replacements, function(key, value) {
        str = str.replace(new RegExp(key, 'g'), value);
    });

    return str;
}

Handlebars.registerHelper('if_eq', function(a, b, options) {

    if(a == b) // Or === depending on your needs
        return options.fn(this);
    else
        return options.inverse(this);
});

Handlebars.registerHelper('ifAllowToUseIt', function(userLevel, maxLevel, options) {
    if(userLevel <= maxLevel) // Or === depending on your needs
        return options.fn(this);
    else
        return options.inverse(this);
});

Handlebars.registerHelper('allowItToSpecificLevel', function(userLevel, allowLevelCommaSeparated, options) {
    var allowedLevels = allowLevelCommaSeparated.split(",");
    var canAllowThisLevel = false;
    for(var i = 0; i < allowedLevels.length; i++)
    {
        if(userLevel == allowedLevels[i])
        {
            canAllowThisLevel = true;
            break;
        }
    }

    if(canAllowThisLevel)
        return options.fn(this);
    else
        return options.inverse(this);
});


Handlebars.registerHelper('ifIsNotNull', function(value, options) {
    if(value) // Or === depending on your needs
        return options.fn(this);
    else
        return options.inverse(this);
});

Handlebars.registerHelper('defaultValueInCaseOfNull', function(value, text, options) {
    if(value) // Or === depending on your needs
        return value;
    else
        return text;
});

Handlebars.registerHelper('defaultValueInCaseOfNullWithTranslation', function(key, value, defaultText, options) {
    if(value) // Or === depending on your needs
        return i18n.t(key + "." + value);
    else
        return i18n.t(key + "." + defaultText);
});

Handlebars.registerHelper('tDay', function(key, options) {
    return i18n.t("store.days." + key);
});


Handlebars.registerHelper('getFullNameOrDefaultValue', function(name, lastname, username, options) {
    return new Handlebars.SafeString(
        (name != null && name != "")
        ? (lastname != null && lastname != "")
            ? lastname + " " + name
            : name
        : (lastname != null && lastname != "")
             ? lastname
             : username
    );
});

Handlebars.registerHelper('round', function(value, toFixed, options) {
    return value.toFixed(toFixed);
});

Handlebars.registerHelper('perCent', function(value1, value2 ,options) {

    var total = value1 + value2;
    total = total == 0 ? 1 : total;
    var perCentValue  = value1/total * 100;
    return parseInt(perCentValue +"");
});

Handlebars.registerHelper('print', function(value1,options) {
    console.log(value1);
});

//Sample usage: span {{t "my.key" }}
Handlebars.registerHelper('t', function (i18n_key, options) {
    var result;
    let count;
    if (options.hash.count) {
        count = parseInt(options.hash.count);
    } else if (options.hash.hasOwnProperty('count')) {
        count = 0;
    }
    if (options.hash) {
        options.hash.count = count;
        result = i18n.t(i18n_key, options.hash);
    } else {
        result = i18n.t(i18n_key);
    }

    return result;
});

//replaces accents from greek upper case characters
Handlebars.registerHelper('tu', function (i18n_key, options) {
    var result;
    let count;
    if (options.hash.count) {
        count = parseInt(options.hash.count);
    } else if (options.hash.hasOwnProperty('count')) {
        count = 0;
    }
    if (options.hash) {
        options.hash.count = count;
        result = i18n.t(i18n_key, options.hash);
    } else {
        result = i18n.t(i18n_key);
    }
    return replaceGreekAccents(result.toUpperCase());
});

Handlebars.registerHelper('getTime', function (dateInMillis, options) {
    // format the date
    // add leading zeroes to single-digit hours/minutes
    let date = new Date(dateInMillis);
    let d = [
        '0' + date.getHours(),
        '0' + date.getMinutes()
    ].map(it => it.slice(-2)); // take last 2 digits of every component

    // join the components into date
    return d.join(':');
});

Handlebars.registerHelper('toFixedOne', function (numb, options) {
    if(isNaN(numb))
        return "-";

    return numb.toFixed(1);
});

Handlebars.registerHelper('getDateTime', function (dateInMillis, options) {
    // format the date
    // add leading zeroes to single-digit day/month/hours/minutes
    let date = new Date(dateInMillis);
    let d = [
        '0' + (date.getMonth() + 1),
        '0' + date.getDate(),
        '0' + date.getHours(),
        '0' + date.getMinutes()
    ].map(it => it.slice(-2)); // take last 2 digits of every component

    // join the components into date
    return date.getFullYear() + '-' + d.slice(0, 2).join('-') + ' ' + d.slice(2).join(':');
});

Handlebars.registerHelper('getDateMoment', function (dateInMillis, options) {
    const date = new Date(dateInMillis);

    moment.locale( i18n.lng() );

    // i.e. Παρασκευή 16 Φεβρουαρίου 2021
    return moment(date).format("dddd D MMMM YYYY");

});

Handlebars.registerHelper('getDateTimeMoment', function (dateInMillis, options) {
    const date = new Date(dateInMillis);

    moment.locale( i18n.lng() );

    // i.e. Παρασκευή 16 Φεβρουαρίου 2021
    return moment(date).format("dddd D MMMM YYYY HH:mm");

});

Handlebars.registerHelper('notHasAvailableSlots', function (currentArray, options) {
    if(!currentArray)
         return options.fn(this);

    if(currentArray.length <= 0)
        return options.fn(this);

    return options.inverse(this);
});

Handlebars.registerHelper('emptySlotsQuantity', function (currentArray, maxSize, options) {
    if(!currentArray || isNaN(maxSize))
         return 0;

    var result = maxSize - currentArray.length;

    if(result <= 0)
        return 0;

    return result;
});

Handlebars.registerHelper('equals', toBlockHelper((v1, v2) => v1 === v2));

Handlebars.registerHelper('notequals', toBlockHelper((v1, v2) => v1 !== v2));

Handlebars.registerHelper('getIfActiveDay', function(day, activeHours, options) {
    if(!day || ! activeHours)
        return options.inverse(this);

    var foundActiveHours = $.grep(activeHours, function (v, k) {
        return v.day == day;
    });
    if (foundActiveHours && foundActiveHours.length >= 1)
        return options.fn(this);

    return options.inverse(this);
});

Handlebars.registerHelper('getActiveHoursOf', function(day, activeHours, options) {
    var foundActiveHours = $.grep(activeHours, function (v, k) {
        return v.day == day;
    });

    if (foundActiveHours && foundActiveHours.length >= 1)
        return foundActiveHours[0];

    return null;
});

Handlebars.registerHelper('if_is', function(user, toCheck, options) {
    if(user && user.authorities && user.authorities[0] == toCheck)
        return options.fn(this);

    return options.inverse(this);
});

Handlebars.registerHelper('convertLongToTime', function (givenMinutes, options) {
    // format the date
    // add leading zeroes to single-digit hours/minutes
    var hours = Math.floor(givenMinutes/60)
    var minutes = givenMinutes % 60

    var displayHours = hours.toString()
    var displayMinutes = minutes.toString()

    if (hours < 10) {
        displayHours = `0${hours}`
    }

    if (minutes < 10) {
        displayMinutes = `0${minutes}`
    }

    return `${displayHours}:${displayMinutes}`;
});


Handlebars.registerHelper('getCurrentHostName', function (options) {
    return `${window.location.protocol}\/\/${window.location.hostname}\/`;
});

Handlebars.registerHelper('ifany', function () {
    /*  e.g. {{#ifany upgradeTypeBasic.length upgradeTypeClassic.length upgradeTypePremium.length '>' 0}}
        **  last argument = valueToCheck (0)
        **  argument before last = method ('>')
        **  all other = array ([upgradeTypeBasic, upgradeTypeClassic, upgradeTypePremium])
        **  arguments[arguments.length - 1] = options
        */
    const stringRepresentation = val => (!val || isNaN(val)) ? `"${val}"` : `${val}`;
    const [method, valueToCheck, opt] = _.last(arguments, 3);
    const repVal = stringRepresentation(valueToCheck);
    const array = _.initial(arguments, 3);
    const operators = [">", "<", "==", "===", "<=", ">=", "!==", "!="];
    if (operators.includes(method) &&
        array.map(stringRepresentation)
            .map(variable => eval(`${variable} ${method} ${repVal}`) === true)
            .reduce((acc, v) => acc || v))
        return opt.fn(this);

    return opt.inverse(this);
});

Handlebars.registerHelper('ifStoreIsTempUnavailableOrDisabled', function( cond1, cond2, cond3, options) {
    if (cond1 == false && (cond2 == false || cond3 == true )) { // store temp unavailable or disabled
        return options.fn(this);
    }
    return options.inverse(this);

});
