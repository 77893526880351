import "../../libs/backbone/backbone.ajaxq.adapter.ts";
import Handlebars from 'handlebars';
var ProgressTemplate = "{{> progressPartialTemplate}}";
import i18n from "l10n";

import Cookies from "js-cookie";

export default Backbone.View.extend({
	Template: null,
	template: null,
	myDomId: null,
	domain: null,
	options: {},
	progressTemplate: Handlebars.compile(ProgressTemplate),
	initialize: function () {

	},
	showHideActionButton: function(flag, btn, tableReference) {
	    if(flag && $(btn).css('display') == 'none' )
            $(btn).show();
        else if ( $(tableReference).find("input:checked").length == 0  )
            $(btn).hide();
	},
	//Run method when user want to go one page back
	goBack: function()
	{
		//with this code we go to the previous page
		window.history.go(-1);
	},
	showFillProgressBar: function()
	{
		$("#progress").html(this.progressTemplate());
	},
	showActionProgressBar: function()
	{
		window.progressBarCounter = window.progressBarCounter + 1;

		$("#container-progress").css("display", "block");
		$("body").addClass("noScroll");
	},
	hideActionProgressBar: function()
	{
		window.progressBarCounter = window.progressBarCounter - 1;
		if(window.progressBarCounter < 0)
			window.progressBarCounter = 0;

		if(window.progressBarCounter == 0)
		{
			$("#container-progress").css("display", "none");
			$("body").removeClass("noScroll");
		}
	},
	privilegeLevelToNumber: function(privilegeLevel)
	{
		if(privilegeLevel == "SupervisingAdmin")
		{
			return 0;
		}else if(privilegeLevel == "DepartmentAdmin")
		{
			return 1;
		}else if(privilegeLevel == "Office Admin")
		{
			return 2;
		}else if(privilegeLevel == "User")
		{
 			return 3;
		}

		return -1;
	},
	numberToPrivilegeLevel: function(number)
	{

		switch(Number(number))
		{
			case 0:
				return "SupervisingAdmin";
			case 1:
				return "DepartmentAdmin";
			case 2:
				return "Office Admin";
			case 3:
				return "User";

		}

		return null;

	},
	isEmpty: function(element, self) {
		if(!self) self = this;

	    // also passing the type, because there are some textareas
		element = $(element);

        var errorSpan = element.parent().prop('tagName') == "FORM" ? element.parent() : element;

		if(element.val().trim() == "")
		{
            self.setError(errorSpan, i18n.t("account.empty_field"));
            return true;
		}
		else
		{
			self.clearError(element);
			return false;
		}
  	},
  	noSpaces: function(element, self) {
        if(!self) self = this;

        // also passing the type, because there are some textareas
        element = $(element);

        var errorSpan = element.parent().prop('tagName') == "FORM" ? element.parent() : element;

        if(element.find("input").val().indexOf(" ") > -1)
        {
            self.setError(errorSpan, i18n.t("account.no_spaces"));
            return true;
        }
        else
        {
            self.clearError(element);
            return false;
        }
    },
  	isNotNumeric: function(element, self) {
		if(!self) self = this;
		element = $(element);

		if(element.find("input").val().trim() != "")
		{
			if(isNaN(Number(element.find("input").val().trim())))
			{
				self.setError(element, i18n.t("account.invalid_number"));
				return true;
			}
		}

		self.clearError(element);
		return false;
    },
  	ignoreEmpty: function(element, self) {
		if(!self) self = this;

		element = $(element);
		var errorSpan = element.parent().prop('tagName') == "FORM" ? element.parent() : element;

		if(element.find("input").val().trim() == "")
		{
			self.clearError(element);
			return true;
		}

		return false;
	},
  	isNotDate: function(element, self)
  	{
  		if(!self) self = this;
  		element = $(element);

		var temp = Date.parse(element.find('input').val());
		//console.info(temp);
		if(isNaN(temp))
		{
			self.setError(element, i18n.t("account.invalid_date"));
			return true;
		}

		self.clearError(element);
		return false;

  	},
  	isNoValidPassword: function(element , self)
	{
		if(!self) self = this;
			element = $(element);

		var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}/;

		if(re.test(element.val()))
		{
			self.clearError(element);
			return false;
		}
		else
		{
			self.setError(element,  i18n.t("account.pswd_prompt"));
			return true;
		}
	},
  	minLengthNumber: function(element, self)
  	{
		if(!self) self = this;
		element = $(element);
		var minLength = element.data("min-length");
		if( !minLength ) throw 'You must declare at the parent div the data-min-length';

		if(element.val().length < minLength)
		{
			self.setError(element, i18n.t("account.min_length")+" " + minLength +" "+i18n.t("account.characters"));
			return true;
		}
		else
		{
			self.clearError(element);
			return false;
		}
  	},
  	maxLengthNumber: function(element, self)
  	{
		if(!self) self = this;
		element = $(element);
		var maxLength = element.data("max-length");
		if( !maxLength ) throw 'You must declare at the parent div the data-max-length';

		var type = "input";
		if(element.data("type"))
			type = element.data("type");

		if(element.val().length >= maxLength)
		{
			self.setError(element, i18n.t("account.max_length")+" " + maxLength  +" "+i18n.t("account.characters"));
			return true;
		}
		else
		{
			self.clearError(element);
			return false;
		}
  	},
  	isEmail: function(element, self) {
		if(!self) self = this;

		element = $(element);
		var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if(!regex.test(element.val()) && element.val().trim() !== "")
		{
            self.setError(element, i18n.t("account.invalid_email"));
            return true;
		}else
		{
            self.clearError(element);
            return false;
		}
	},
	isPhone: function(element, self) {
		if(!self) self = this;

        element = $(element);
        var regex1 = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})([0-9]{4})/;
        var regex2 = /\(?([0-9]{4})\)?([ .-]?)([0-9]{3})([0-9]{3})/;

        if(!regex1.test(element.val()) && !regex2.test(element.val()) && element.val().trim() !== "")
        {
            self.setError(element, i18n.t("account.invalid_phone"));
            return true;
        }
        else
        {
            self.clearError(element);
            return false;
        }
    },
    sameValues: function(element, self)
    {
		if(!self) self = this;

        element = $(element);
        var element_to_confirm = $(element.data("compare-with"));
        if( !element_to_confirm ) throw 'You must declare at the parent div the data-compare-with';

        if(element.val() != element_to_confirm.val())
        {
            self.setError(element, i18n.t("account.pswds_not_match"));
            return true;
        }
        else
        {
            self.clearError(element);
            return false;
        }
    },
    isNotChecked: function(element, self)
    {
        if(!self) self = this;

        element = $(element);

        if(element.prop("checked") == false)
        {
            self.setError(element, i18n.t("error.is_not_checked"));
            return true;
        }
        else
        {
            self.clearError(element);
            return false;
        }
    },
    isLessThan: function(element, self)
    {
		if(!self) self = this;

        element = $(element);
        element_to_evaluate = $(element.data("evaluate-with"));
        if( !element_to_evaluate ) throw 'You must declare at the parent div the data-evaluate-with';

        if(Number(element.find("input").val()) <= Number(element_to_evaluate.find("input").val()))
        {
            self.setError(element, i18n.t(element.data("eval-msg")));
            return true;
        }
        else
        {
            self.clearError(element);
            return false;
        }
    },
	isNotSelect: function(element, self)
	{
		if(!self) self = this;

		element = $(element);

		if(element.find("select").val() == null)
		{
			self.setError(element, i18n.t("advance_options.select_item"));
			return true;
		}
		else
		{
			self.clearError(element);
			return false;
		}
	},
    isGreaterThan: function(element, self) {
        if(!self) self = this;

        element = $(element);
        var greaterThan = element.data("greater-than");
        /*if( !greaterThan ) throw 'You must declare at the parent div the data-greater-than';*/

        var errorSpan = element.parent().prop('tagName') == "FORM" ? element.parent() : element;

        if(element.val() <= greaterThan)
        {
            self.setError(errorSpan, i18n.t("error.greater_than") + " " + greaterThan);
            return true;
        }
        else
        {
            self.clearError(element);
            return false;
        }
    },
	characterLeft: function(elementTag)
	{
 		var element = $(elementTag);
        var maxLength = element.data("max-length");
        var length = element.find("textarea").val().trim().length;
        var diff = maxLength-length;
        element.find("span#char_counter").html(diff+"");

        var maxLength = element.data("max-length");
        if(diff < 0)
        {
            this.setError(element, i18n.t("account.max_length")+" " + maxLength +" "+i18n.t("account.characters"));
            return true;
        }

        this.clearError(element);
        return false;
	},
	setError: function(element, error_msg)
	{
        if((typeof element) == "string")
        	element = $(element);

        // finds the correct div, in order to addClass 'has-error'
        var errorDiv = element.prop('tagName') == "FORM" ? element.parent() : element;
        errorDiv.addClass("has-error");
        errorDiv.siblings("span.error-block").text(error_msg);
	},
	clearError: function(element)
	{
	 	if((typeof element) == "string")
			element = $(element);
	    // finds the correct div, in order to removeClass 'has-error'
	    var errorDiv = element.parent().prop('tagName') == "FORM" ? element.parent().parent() : element;

        // problem with implementation
		errorDiv.removeClass("has-error");
		errorDiv.siblings("span.error-block").text("");
	},
	validateFields: function(fields)
	{
		var has_error = false;
		var keys = Object.keys(fields);

        for(var i = 0; i < keys.length; i++)
        {
			//console.info(keys[i]);
            var validations = fields[keys[i]];
            for(var k = 0; k < validations.length; k++)
            {
                if(typeof keys[i] === "function")
                    keys[i]();
                else if(validations[k]('#'+keys[i], this))
                {
                    has_error = true;
                    break;
                }
            }
        }
		return has_error;
	},
	onlyNumbers: function(event)
	{
		var KeyID = event.charCode;
		var temp = event.keyCode;
		switch(KeyID == 0 ? temp : KeyID)
		{
			case 8:
			case 9:
			case 46:
			case 48:
			case 49:
			case 50:
			case 51:
			case 52:
			case 53:
			case 54:
			case 55:
			case 56:
			case 57:
			return true;


		}
		return false;

	},
	onDataHandler: function(collection, response, options) {
  	},
    onErrorHandler: function(collection, xhr, options) {
		document.cookie = "status=" + xhr.status;
		if (xhr.status == 401) {
			window.open('./','_self');
		}else if(xhr.status == 403){
			$.ajaxq.abort("DefaultQueue");
			window.open('./','_self');
		}
  	},
  	getTodayWithOffset: function(dayOffset) {
        var today = new Date();
        today.setDate(today.getDate() - dayOffset);

        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if(dd < 10) {
            dd = '0' + dd
        }

        if(mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd;

        return today;
    },
    reEvaluate: function(element, self, callback) {
        if(!self) self = this;

        element_to_reevaluate = $(element.data("reevaluate-field"));
        if( !element_to_reevaluate )
            return;

        return callback(element_to_reevaluate, self);
    },
    setCookie: function(name, value, expiresInDays) {
        expiresInDays = expiresInDays ? expiresInDays : 1;

        Cookies.set(name, value, {
           expires : expiresInDays,                 // Expires in 1 days

           path    : '',                            // The value of the path attribute of the cookie
                                                    // (Default: path of page that created the cookie).

           domain  : window.location.hostname,      // The value of the domain attribute of the cookie
                                                    // (Default: domain of page that created the cookie).

           secure  : true                           // If set to true the secure attribute of the cookie
                                                    // will be set and the cookie transmission will
                                                    // require a secure protocol (defaults to false).
        });

    },
    getCookie: function(cookieName) {
        return Cookies.get(cookieName);
    },
    removeCookie: function(cookieName) {
        return Cookies.remove(cookieName);
    },
    scrollToLink: function(link) {
        let $block = $( $("#"+ link) );
        const offsetTop = $block.offset().top; // elements position in the window (position of "top left pixel")
        const currentScrollTop = $(window).scrollTop(); // the current scrolled position in document

        let newScrollPosition = offsetTop - currentScrollTop;

        // fix the scroll position
        if(newScrollPosition < currentScrollTop)
            newScrollPosition += currentScrollTop;

        var $body = $("html, body"); //needs both body and html to scroll

        $body.stop().animate({scrollTop: newScrollPosition}, 500, 'swing', function() {

        });
    },
    scrollTo: function(e, options) {
        let $elem = $(e.currentTarget);

        let $block = $( $elem.data("id") );
        const offsetTop = $block.offset().top; // elements position in the window (position of "top left pixel")
        const currentScrollTop = $(window).scrollTop(); // the current scrolled position in document

        let newScrollPosition = offsetTop - currentScrollTop;

        // fix the scroll position
        if(newScrollPosition < currentScrollTop)
            newScrollPosition += currentScrollTop;

        // add some padding on scroll
        if(options && options.paddingTop)
            newScrollPosition -= options.paddingTop;

        var $body = $("html, body"); //needs both body and html to scroll

        $body.stop().animate({scrollTop: newScrollPosition}, 500, 'swing', function() {

        });

        var $nav = $("#main_nav").find(".navbar-nav");

        if($nav.hasClass("shown"))
            $("#mobile-nav-toggle").trigger("click"); // close the menu (if open)

        return false;
    },
    scrollTop: function() {
        var $body = $("html, body"); //needs both body and html to scroll

        $body.stop().animate({scrollTop: 0}, 500, 'swing', function() {

        });

        return false; // needed to ensure the href will not work
    },
    toggleMenu: function(e) {
        $('body').toggleClass('mobile-nav-active');
        $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');

        var leftValue = 0;
        var $mobileNav = $('#main_nav');


        if($mobileNav.css("left") == "0px")
            leftValue = "-100%";
        else
            leftValue = "0px";

        $mobileNav.toggleClass("shown");
        $mobileNav.find('.navbar-nav').toggleClass("shown");
    },
	checkVat: async function (taxPayerId) {
		var formValues = {
			vatNumber: taxPayerId,
			country: "EL"
		};
		const response = await $.ajaxDefaultQ({
			url: window.ip+`/rest/open/checkvat`,
			type: "POST",
			dataType: "json",
			data: JSON.stringify(formValues),
            contentType : 'application/json',
		});
		if (response.status === "success") {
			if (response.message === "isValid") {
				return true;
			} else {
				return false;
			}
		}
	},
	togglePassWordViewableGeneric: function (event) {
		var $currentTarget = $(event.currentTarget);
		var $passInput = $("#" + $currentTarget.data("id"));
		if ($passInput.attr("type") === "password") {
			$passInput.attr("type", "text");
			$($currentTarget.find("span")).text(i18n.t("account.hide"));
		} else {
			$passInput.attr("type", "password");
			$($currentTarget.find("span")).text(i18n.t("account.show"));
		}
		$currentTarget.find("i").toggleClass("far fa-eye far fa-eye-slash");
		event.preventDefault();
	},
	facebookAuthOpenDialog: function () {
		$.ajaxDefaultQ({
			url: window.ip+'/rest/oauthClient/createFacebookAuthorization',
			type: 'GET',
		}).done(function (locationUri) {

			console.log(locationUri);
			window.open(`${locationUri}`,'_self');
		}).fail(function (error) {
			console.error("Problem with open facebook dialog" + error);
		})
	},
	googleAuthOpenDialog: async function () {
		$.ajaxDefaultQ({
			url: window.ip+'/rest/oauthClient/createGoogleAuthorization',
			type: 'GET',
		}).done(function (locationUri) {

			console.log(locationUri);
			window.open(`${locationUri}`,'_self');
		}).fail(function (error) {
			console.error("Problem with open google dialog" + error);
		})
	}
});
