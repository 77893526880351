import '../../../libs/backbone/backbone.ajaxq.adapter.ts';
import GenericView from '../../prototypes/generic.view.ts';
import Handlebars from 'handlebars';
import Template from 'text-loader!../templates/navbarTop.template.hbs';
import i18n from 'l10n';
import "compat";

import Cookies from "js-cookie";

export default GenericView.extend({
    el: $("#header"),
    tagName: 'header',
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	langs: {},
	userData: null,
	page: null,
	initialize: function (options) {
	    this.langs = {
            "el": "gr",
            "en": "gb"
        };

        this.userData = options ? options.userData : null;
        this.page = options ? options.page : null;

        this.render();

        this.checkForCookiesUsageAcceptance();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;

        var templateParams = {
            "user": this.userData,
            "page": this.page
        };

		this.$el.html(this.template(templateParams));

		this.$('#language-selector li a').each(function(a) {
            self.$(this).click(function(evt) {
                var lang = self.$(this).data("lang");
                i18n.setLng(lang);
                evt.preventDefault(); //stop page from reloading
                location.reload();
            })
        });

        return this;
	},
	events:{
	    "click #logout" : "logout",
	    "click .show-menu": "toggleMenu",
        "click #stores": "openModal",
        "click .scrollToLink": "scrollTo",
        "click #acceptCookiesUsage": "acceptCookiesUsage"
	},
	checkForCookiesUsageAcceptance: function() {
	    var cookiesAcceptance = GenericView.prototype.getCookie("COOKIES_ACCEPTANCE");

	    if(!cookiesAcceptance) {
            setTimeout(function() {
                $("#cookies-acceptance").animate({
                    "bottom": "0px"
                }, 350);
            }, 1500);
        }
	},
	acceptCookiesUsage: function() {
	   GenericView.prototype.setCookie("COOKIES_ACCEPTANCE", "true", 30);
	   $("#cookies-acceptance").hide();
	},
	toggleMenu: function(e) {
        GenericView.prototype.toggleMenu();
    },
    openModal: function() {
        $("header").addClass("remove-logo");
    },
	scrollTo: function(e) {
        e.preventDefault(); // prevent "href" functionality

	    return GenericView.prototype.scrollTo(e);
    },
    getLangCode: function(lng) {
        return this.langs[lng];
    },
	logout: function(e) {
		e.preventDefault();

        if( $.ajaxq.isRunning("DefaultQueue"))
            $.ajaxq.abort("DefaultQueue");

        $.ajaxDefaultQ({
            url: window.ip+'/rest/api/users/me/logout',
            type: 'POST',
        }).done(function(response, status, xhr){
            window.open('login.html','_self');
        })
        .fail(function(response) {

        });

        return false;
	}
});