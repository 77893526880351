import dictionary from "../../../dictionary/texts.json";
var greek = dictionary["el"];
var english = dictionary["en"];
import i18n from "i18next";

const DEFAULT_LANG = "el";
const AVAILABLE_LANGS = ["en", "el"];

i18n.init({
    fallbackLng: DEFAULT_LANG,
    resStore: dictionary,
    lngWhitelist: AVAILABLE_LANGS,
    defaultLng: DEFAULT_LANG
});

var translations = {
    "el": greek,
    "en": english
};

i18n.truncLang = function () {
    var i = i18n.options.lng.indexOf("-");
    if (i === -1) {
        return i18n.options.lng;
    }
    else {
        return i18n.options.lng.substring(0, i);
    }
};


i18n.findCountryFromCode = function (code) {
    var array = translations[i18n.options.lng];
    return array[code];
};

i18n.getCountries = function () {
    return translations[i18n.options.lng];
};

console.log("Loaded dictionary. Translation of Language: " + i18n.t("language"));

export default i18n;
