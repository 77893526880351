import '../../../libs/backbone/backbone.ajaxq.adapter.ts';
import GenericView from '../../prototypes/generic.view.ts';
import Handlebars from 'handlebars';
import Template from 'text-loader!../templates/footer.template.hbs';
import "compat";

export default GenericView.extend({
    el: "#footer",
    tagName: '',
	template: Handlebars.compile(Template),
	superInitialize: GenericView.prototype.initialize,
	initialize: function (options) {
        this.render();

        this.bindEvents();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;


		this.$el.html(this.template());

	},
	events:{
        "click .back-to-top": 'backToTop'
	},
	backToTop:  function(e) {
        e.preventDefault(); // prevent "href" functionality
        return GenericView.prototype.scrollTop();
    },
    bindEvents: function() {
        $(window).scroll(function() {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn('slow');
                $('#header').addClass('header-scrolled');
            }
            else {
                $('.back-to-top').fadeOut('slow');
                $('#header').removeClass('header-scrolled');
            }
        });

        if ($(window).scrollTop() > 100) {
            $('.back-to-top').fadeIn('slow');
            $('#header').addClass('header-scrolled');
        }
    }
});
