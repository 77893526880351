import "../libs/backbone/backbone.ajaxq.adapter.ts";
import "../libs/bootstrap/js/bootstrap.min.js";
import "../libs/notify/notify.min.js";
import "global_functions.js";
import i18n from 'l10n';

$('html').attr('lang', i18n.lng());

import Cookies from "js-cookie";

import TheRouter from "professional/routers/professional.router.ts";

window.ip = "https://voueasy.gr"
//window.ip = ""; //SERVER IP
//window.ip = "http://172.16.0.42:8181";

var csrfToken = null;
var data = null;
var loggedIn = false;

var translations = $("[data-i18n]");
$.each(translations, function(i, translation) {
    var text = i18n.t( $(translation).data("i18n") );
    $(translation).text( text );
});

/*$.ajaxSetup({
    beforeSend: function (xhr) {
        var lang = i18n.lng() == "en" ? "en-GB" : "el-GR";

        xhr.setRequestHeader('X-CSRF-TOKEN', window.csrf);
        xhr.setRequestHeader('Accept-Language', lang);

    },
    error: function (xhr, exception) {

    }
});*/

$.ajaxDefaultQ({
	url: window.ip+'/rest/api/users/me',
	type: 'GET',
}).done(function(response, status, xhr){
	if(status == "success") {
		loggedIn = true;
		data = response.data;
	}
	// redirect to login, should be in the router
})
.fail(function(response) {
    // redirect to login, should be in the router
})
.always(function(){
	var router = new TheRouter({csrfToken: csrfToken, loggedIn: loggedIn, userData: data});
	Backbone.history.start();
});






