import "../../../libs/backbone/backbone.ajaxq.adapter.ts";
import GenericView from '../../prototypes/generic.view.ts';

import Handlebars from 'handlebars';
import HandlebarsHelpers from 'handlebarsHelpers';

import Template from 'text-loader!../templates/professional.template.hbs';

import i18n from "l10n";


export default GenericView.extend({
    el: "#main",
    tagName: '',
	template: Handlebars.compile(Template),
	initialize: async function (options) {
		var self = this;

		this.link = options ? options.link : null;

		this.render();
	},
	superRender: GenericView.prototype.render,
	render: function () {
		this.superRender();
		var self = this;

		this.$el.html(this.template());

        this.checkAndScroll();

		return this;
	},
	events:{
		"click .show-menu": "toggleMenu",
		"click .scrollToLink": "scrollTo",
        "click #acceptCookiesUsage": "acceptCookiesUsage"
	},
	checkAndScroll: function() {
	    if(this.link){
	        GenericView.prototype.scrollToLink(this.link);
	    }
	}
});
