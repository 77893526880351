import '../../../libs/backbone/backbone.ajaxq.adapter.ts';
import Handlebars from "handlebars";

import NavBarTopView from 'navbarTopView';
import FooterView from 'footerView';

import ProfessionalView from "../views/professional.view.ts";

export default Backbone.Router.extend({
	routes: {
		"": "professionalRoute",
		"(:link)": "professionalRouteWithLink",
		"Appointment/(:appointmentHash)": "appointmentRoute",
		"stores/:city/:uniqueStoreName": "appointmentsRoute",
	},
	loggedIn: false,
	userData: null,
	initialize: function (options) {
		self = this;
		this.loggedIn = options ? options.loggedIn : false;
		this.userData = options ? options.userData : null;
	},
    "404Route": function() {
        //todo: show 404 page
    },
    "403Route": function() {
        //todo: show 403 page
    },
    professionalRoute: function() {
        this.clearView();
        this.createTheNavBars();
        this.view = new ProfessionalView();
    },
    professionalRouteWithLink: function(link) {
        this.clearView();
        this.createTheNavBars();
        this.view = new ProfessionalView({"link": link});
    },
    appointmentRoute: function(appointmentHash) {
        window.open(`/customer.html#Appointment/${appointmentHash}`, "_self");
    },
    appointmentsRoute: function (city, uniqueStoreName) {
        window.open(`/singleAppointment.html#${city}/${uniqueStoreName}`, "_self");
    },
    createTheNavBars: function() {
		this.footer = new FooterView();
		this.navbartop = new NavBarTopView({"userData": this.userData, "page": "professional"});
	},
	clearView: function(){
		if(this.view){
			$.ajaxq.abort("DefaultQueue");

			this.view.stopListening();
			this.view.$el.empty();

			if(this.view.clearView)
				this.view.clearView();

			this.view.undelegateEvents();

			delete this.view;

			$("body").scrollTop(0);
		}

		var infoViews = [
		    this.navbartop,
		    this.footer,
		    this.mainInfo
		];

        $.each(infoViews, function(i, view) {
            if(view) {
                view.stopListening();
                view.undelegateEvents();
                view.$el.empty();
                delete view.source;
            }
        });
	}
});

